import "./css/App.css";
import OmicsWorkspace from "./components/OmicsWorkspace";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE!);

function Workspace() {
  return <OmicsWorkspace />;
}

export default Workspace;
