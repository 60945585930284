import React from 'react';
import Card from './workspace/Card';
import StatusCard from './workspace/StatusCard';
import '../css/Workspace.css';

const Workspace: React.FC = () => {
    return (
        <div className="workspace-container">
            {/*<div className="navbar">Workspace Header</div>*/}

            <div className="workspace-content">
                <div className="sidebar">
                    <StatusCard />
                    <StatusCard />
                    <StatusCard />
                    <StatusCard />
                    <StatusCard />
                </div>

                <div className="main-content">
                    {/* 1st Row */}
                    <div className="row-info">
                        <div>Bioproject <br/><span>XXX</span></div>
                        <div>Study_accession <br/><span>YYY</span></div>
                        <div>Sequencing_assay <br/><span>ZZZ</span></div>
                    </div>
                    <hr/>
                    {/* 2nd Row */}
                    <div className="row-info">
                        <div>Number of experiments <br/><span>174</span></div>
                        <div className="vertical-divider"></div>
                        <div>processable <br/><span>138</span></div>
                        <div>Number of runs <br/><span>134</span></div>
                        <div>processable <br/><span>38</span></div>
                    </div>
                    <hr/>
                    {/* 3rd Row */}
                    <div className="row-info rounded-components">
                        <Card
                            title="Dataset informations"
                            infoList={[
                                {key: 'disease', value: 'running nose'},
                                {key: 'Submission date', value: '01/01/2020'},
                                {key: 'Public date', value: '01/01/2021'},
                                {key: 'Genome', value: 'hg38'},
                                {key: 'Organ', value: 'CNS'},
                                {key: 'Cell', value: 'Astrocyte'},
                            ]}
                        />
                        <Card
                            title="Keywords & Ontology ID"
                            infoList={[
                                {key: 'Public date', value: '01/01/2021'},
                                {key: 'Genome', value: 'hg38'},
                                {key: 'Organ', value: 'CNS'},
                                {key: 'Cell', value: 'Astrocyte'},
                            ]}
                        />
                        <Card
                            title="Article informations"
                            infoList={[
                                {key: 'Public date', value: '01/01/2021'},
                                {key: 'Genome', value: 'hg38'},
                                {key: 'Organ', value: 'CNS'},
                                {key: 'Cell', value: 'Astrocyte'},
                            ]}
                        />
                        <Card
                                title="Sampling informations"
                            infoList={[
                                {key: 'Public date', value: '01/01/2021'},
                                {key: 'Genome', value: 'hg38'},
                                {key: 'Organ', value: 'CNS'},
                                {key: 'Cell', value: 'Astrocyte'},
                            ]}
                        />
                    </div>
                </div>

                <div className="right-sidebar">
                    Comments thread
                    <ul>
                        <li>Right Item 1</li>
                        <li>Right Item 2</li>
                        <li>Right Item 3</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Workspace;