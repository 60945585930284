import React from 'react';
import '../../css/Card.css';

interface CardProps {
    title: TitleKey;
    infoList: { key: string, value: string }[];
}

type TitleKey = 'Dataset informations' | 'Keywords & Ontology ID' | 'Article informations' | 'Sampling informations';

const titleColors = {
    'Dataset informations': '#E9D5FF',
    'Keywords & Ontology ID': '#F2D0EB',
    'Article informations': '#FED3D2',
    'Sampling informations': '#FEE5CD'
};

const Card: React.FC<CardProps> = ({title, infoList}) => {
    const titleColor = titleColors[title] || '#FFFFFF'; // Fallback to white if title is not found in the map

    return (
        <div className="card-component">
            <div className="card-title" style={{ backgroundColor: titleColor }}>{title}</div>
            {infoList.map((info, index) => (
                <div key={index} className="card-info">
                    <span className="info-key">{info.key}</span>
                    <span className="info-value">{info.value}</span>
                </div>
            ))}
        </div>
    );
}

export default Card;
