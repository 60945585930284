import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

import {AgGridReact} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./css/index.css";

import Explorer from "./Explorer";
import Dashboard from "./Dashboard";
import Workspace from "./Workspace";
import NavBar from "./NavBar";
import {Login} from "./components/others/Login";
import TC from "./components/others/TC";
import {isJwtTokenExpired} from "./verifyToken";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import {fetchMe} from "./api/api";

import MyDatasetsButton from "./components/buttons/MyDatasetsButton";
import MySelectionButton from "./components/buttons/MySelectionButton";

interface IDatasetData {
    id: number;
    name: string;
    organism: string;
    status: string;
    number_runs: number;
}

Sentry.init({
    dsn: "https://de896f4c40968a9c21385936b260b3f6@o4505940663140352.ingest.sentry.io/4505940668973056",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // TODO: https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/api\.yourserver\.io\/api/,
            ],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: true,
        }),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: process.env.NODE_ENV === 'dev' ? 0.1 : 1., // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [totalNumberRuns, setTotalNumberRuns] = useState(0);
    const [filterState, setFilterState] = useState(null);
    const [currentPage, setCurrentPage] = useState("Dashboard");
    const [isSlideUpVisible, setIsSlideUpVisible] = useState(false);
    const [isSlideUpVisible2, setIsSlideUpVisible2] = useState(false);
    const [numFiltered, setNumFiltered] = useState(0);
    const [credits, setCredits] = useState(0);
    const [email, setEmail] = useState("example@email.com");

    const gridRef = useRef<AgGridReact<IDatasetData>>(null);

    const calculateSelectedNumberRuns = () => {
        // (2)
        const selectedNodes = gridRef.current!.api.getSelectedNodes();

        const sum =
            selectedNodes?.reduce((accumulator, currentNode) => {
                // @ts-ignore
                return accumulator + (currentNode.data.number_runs || 0);
            }, 0) || 0;

        setTotalNumberRuns(sum);
    };

    const fetchCredits = () => {
        fetchMe(setCredits, setEmail);
    };

    useEffect(() => {
        if (isAuthenticated) fetchCredits();
    }, [isAuthenticated]);

    useEffect(() => {
        if (email !== "example@email.com") {
            Sentry.setUser({email});
        }
    }, [email]);


    useEffect(() => {
        if (
            localStorage.getItem("catalog_access_token") !== null &&
            !isJwtTokenExpired(localStorage.getItem("catalog_access_token"))
        ) {
            setIsAuthenticated(true);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (filterState && Object.keys(filterState).length) {
            setIsSlideUpVisible(true); // Show the div when filterState is not empty
        } else {
            setIsSlideUpVisible(false); // Hide the div when filterState is empty
        }
    }, [filterState]);

    useEffect(() => {
        if (totalNumberRuns > 0) {
            setIsSlideUpVisible2(true);
        } else {
            setIsSlideUpVisible2(false);
        }
    }, [totalNumberRuns]);

    useEffect(() => {
        const getTokenFromURL = () => {
            const searchParams = new URLSearchParams(window.location.search);
            return searchParams.get("refresh");
        };

        const getNewToken = async (refresh: string) => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/token/refresh/`,
                    {refresh},
                    {headers: {"Content-Type": "application/json"}}
                );
                if (response.status === 200) {
                    localStorage.setItem("catalog_access_token", response.data.access);
                    localStorage.setItem("catalog_refresh_token", response.data.refresh);
                    setIsAuthenticated(true);

                    // Remove the refresh token from the URL
                    const newUrl = window.location.href.split("?")[0];
                    window.history.replaceState({}, document.title, newUrl);
                }
            } catch (error) {
                console.error("Error refreshing token:", error);
            }
        };

        const refresh = getTokenFromURL();

        if (refresh) {
            getNewToken(refresh);
            // Verify and store the token, then navigate to the desired page
        }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
                setNumFiltered(displayedRowCount);
            }
        }, 1000);
        return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts
    }, [filterState]);

    // Check if the user is not authenticated, if so redirect to the Login page
    if (!isAuthenticated) return <Login setIsAuth={setIsAuthenticated}/>;

    const startExploringDatasets = () => {
        setCurrentPage("Explorer");
    };

    return (
        <>
            <NavBar
                gridRef={gridRef}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <div className="light-grey-background">
                <div className={currentPage === "Explorer" ? "" : "hidden"}>
                    <Explorer
                        gridRef={gridRef}
                        onSelectionChanged={calculateSelectedNumberRuns}
                        setFilterState={setFilterState}
                    />
                    <div
                        className={`slide-up-bottom ${isSlideUpVisible2 ? "visible" : ""}`}
                    >
                        <MySelectionButton
                            gridRef={gridRef}
                            numCredits={totalNumberRuns}
                            myCredits={credits}
                            email={email}
                            onSubmit={fetchCredits}
                        />
                    </div>
                </div>
                <div className={currentPage === "Dashboard" ? "" : "hidden"}>
                    <Dashboard gridRef={gridRef} filterState={filterState}/>
                    {filterState && (
                        <div
                            className={`slide-up-bottom ${isSlideUpVisible ? "visible" : ""}`}
                        >
                            <MyDatasetsButton
                                numFiltered={numFiltered}
                                filterModel={filterState}
                            />

              <button onClick={startExploringDatasets}>
                Start exploring datasets
              </button>
            </div>
          )}
        </div>
        <div className={currentPage === "Workspace" ? "" : "hidden"}>
          <Workspace />
        </div>
        <TC />
      </div>
    </>
  );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

reportWebVitals();
