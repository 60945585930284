import React from 'react';
import '../../css/StatusCard.css';

const StatusCard = () => {
    return (
        <div className="card">
            <div className="card-header">
                <div className="status">Selected</div>
                <img
                        src="/notification.svg"
                        alt="Green Tick"
                        style={{maxWidth: "40px", maxHeight: "40px"}}
                    />
            </div>
            <div className="card-body">
                <div className="availability">
                    <img
                        src="/green-tick.svg"
                        alt="Green Tick"
                        style={{maxWidth: "40px", maxHeight: "40px"}}
                    />
                    Available
                </div>
                <div className="processability">
                    <img
                        src="/green-tick.svg"
                        alt="Green Tick"
                        style={{maxWidth: "40px", maxHeight: "40px"}}
                    />
                    Processable
                </div>
            </div>
            <div className="card-footer">
                <span className="dataset-id">Dataset_id</span>
                <span className="gse">GSE133028</span>
            </div>
        </div>
    );
}

export default StatusCard;
