import React, { useEffect, useState } from "react";
import axios from "axios";
import { AgChartsReact } from "ag-charts-react";
import {
  AgChartOptions,
  AgNodeClickEvent,
  AgChartLegendClickEvent,
} from "ag-charts-community";
import chroma from "chroma-js";
import "../../css/BarChart.css";

type BarChartProps = {
  gridRef: any;
  filterState: any;
};

const BarChart: React.FC<BarChartProps> = ({ gridRef, filterState }) => {

  const [options, setOptions] = useState<AgChartOptions>({
    theme: "ag-default",
    title: {
      text: "Databases",
    },
    data: [],
    series: [], // Initialize as an empty array; will populate after fetching data
    legend: {
      enabled: true,
      position: "right",
    },
    autoSize: true,
    axes: [
      {
        type: "category",
        position: "left",
        tick: { enabled: false }, // Remove Y axis ticks
        label: { enabled: false }, // Remove Y axis labels
        gridStyle: [],
      },
      {
        type: "log",
        position: "bottom",
        gridStyle: [],
      },
    ],
    listeners: {
      seriesNodeClick: (event: AgNodeClickEvent) => console.log("click", event),
    },
  });

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_BACKEND_URL}/count_datasets?field=db_name`;
        if (filterState) {
          for (const [key, value] of Object.entries(filterState)) {
            if (value && "db_name" !== key) {
              // @ts-ignore
              url += `&${key}=${value.filter}`;
            }
          }
        }
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "catalog_access_token"
            )}`,
          },
        });

        // Convert API data to chart data format
        const chartData = response.data.map(
          (item: { count: any; db_name: any }) => ({
            quantity: item.count > 0 ? Math.log10(item.count) : 0,
            [item.db_name]: item.count,
          })
        );

        var dbs = ["ngdc", "arrayexpress", "geo", "dbgap", "ega", "ebi", "bioproject", "ddbj"]
        
        const colorScale = chroma
          .scale(["#4D1DD9", "#4EA599", "#F6BE00", "#693C5E"])
          .mode("lch")
          .colors(dbs.length);

        const dbNameToColor: { [key: string]: string } = {};

        dbs.forEach((dbName, index) => {
          dbNameToColor[dbName] = colorScale[index % colorScale.length];
        });

        // Create series data for each database name
        const seriesData = response.data.map(
          (item: { db_name: any}, index: number) => ({
            type: "bar",
            xKey: "quantity",
            yKey: item.db_name,
            yName: item.db_name,
            fill: dbNameToColor[item.db_name],
            stacked: true,
            tooltip: {
              enabled: true,
              renderer: function (params: { datum: any; yKey: any }) {
                return `
                                <div class="tooltip-content">
                                ${params.yKey} : ${params.datum[params.yKey]}
                                </div>`;
              },
            },
          })
        );

        setOptions((prevOptions) => ({
          ...prevOptions,
          data: chartData,
          series: seriesData,
          legend: {
            enabled: true,
            position: "right",
            listeners: {
              legendItemClick: (event: AgChartLegendClickEvent) => {
                const currentFilter = gridRef.current!.api.getFilterModel();

                currentFilter["db_name"] = {
                  filterType: "text",
                  type: "notContains",
                  filter: event.itemId,
                };

                gridRef.current!.api.setFilterModel(currentFilter);
                gridRef.current!.api.onFilterChanged();
              },
            },
          },
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [filterState]);

  return (
    <div className="chart-container">
      <AgChartsReact options={options} />
    </div>
  );
};

export default BarChart;
